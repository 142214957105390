// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CF40O\\+9X{align-items:center;display:flex;flex-shrink:0;height:var(--header-height);margin-right:calc(var(--spacing-default)*10);max-width:calc(var(--header-height)*2);padding:calc(var(--header-height)*.2) 0}.CF40O\\+9X img{-o-object-fit:contain;object-fit:contain;-o-object-position:left;object-position:left}.CF40O\\+9X img,.CF40O\\+9X svg{height:100%;width:100%}._8ssVVzgN>:first-child{fill:var(--color-fg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "CF40O+9X",
	"logo": "_8ssVVzgN"
};
module.exports = ___CSS_LOADER_EXPORT___;
