// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tTbzez\\+W{display:flex;flex-grow:1;height:100%;justify-content:space-between;overflow:hidden}@media(max-width:1023.98px){.tTbzez\\+W{flex-direction:row-reverse}}.gLU\\+p50m{display:flex;flex-grow:1;height:100%;overflow:hidden}@media(max-width:1023.98px){.gLU\\+p50m{background:var(--color-bg);flex-direction:column;height:calc(100% - var(--header-height));justify-content:flex-start;left:0;overflow-y:auto;position:fixed;top:var(--header-height);visibility:hidden;width:100%;z-index:-1}.gLU\\+p50m.htSE0loJ{visibility:visible}}.Q1NXoMxZ{align-self:center;display:none}.Q1NXoMxZ>:not(:last-child){margin-right:var(--spacing-xs)}@media(max-width:1023.98px){.Q1NXoMxZ{display:flex}}.st92XFnk{--icon-size:1.5rem;padding:var(--spacing-xs)}.ttdHV0wR{background:var(--color-gray-1)}.UOK7QPcO{align-items:center;display:flex;flex-shrink:0;flex-wrap:nowrap;height:100%;opacity:.5}.UOK7QPcO>*{background:var(--color-primary)}.UOK7QPcO>:first-child{border-radius:9999px;flex-shrink:0;height:1.5rem;margin-right:var(--spacing-sm);overflow:hidden;width:1.5rem}.UOK7QPcO>:last-child{border-radius:9999px;height:.5rem;overflow:hidden;width:5rem}@media(max-width:1023.98px){.UOK7QPcO{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "tTbzez+W",
	"content": "gLU+p50m",
	"modalOpen": "htSE0loJ",
	"btns": "Q1NXoMxZ",
	"btn": "st92XFnk",
	"btnMenu": "ttdHV0wR",
	"userPlaceholder": "UOK7QPcO"
};
module.exports = ___CSS_LOADER_EXPORT___;
