// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4FJL\\+SG4{align-items:center;color:var(--color-bg-invert);display:flex;font-size:.75rem;justify-content:center;line-height:1.6}@media(max-width:667.98px){._4FJL\\+SG4{flex-direction:column;font-size:.69rem}}.vioZKNLU{align-items:center;color:inherit;display:flex;flex-wrap:nowrap;font-weight:700;margin-right:var(--spacing-md);white-space:nowrap}@media(max-width:667.98px){.vioZKNLU{margin-right:0}}.oKwFO-g1{flex-shrink:0;height:2rem;margin-right:var(--spacing-sm)}.oKwFO-g1>*{fill:currentColor}.vioZKNLU,._73E2SHCg{min-height:3rem}._73E2SHCg{opacity:.7}@media(max-width:667.98px){._73E2SHCg{margin-bottom:var(--spacing-xs);min-height:0;min-height:auto;text-align:center}}._73E2SHCg a{align-items:center;color:inherit;display:inline-flex;min-height:inherit;-webkit-text-decoration:underline;text-decoration:underline}@media(hover:hover){._73E2SHCg a:hover{-webkit-text-decoration:none;text-decoration:none}}@media(hover:none){._73E2SHCg a:active{-webkit-text-decoration:none;text-decoration:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_4FJL+SG4",
	"copy": "vioZKNLU",
	"copyLogo": "oKwFO-g1",
	"terms": "_73E2SHCg"
};
module.exports = ___CSS_LOADER_EXPORT___;
