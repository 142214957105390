// TODO: Заменить Element уведомления на свои

import { Notification } from "element-ui"
import { msgToStr } from "../utils/_"
import { ChatPrivate, parseCtx } from "./chat"

let chat: ChatPrivate

const getStart = (ctx: NuxtContext) => async () => {
  const { store, $config } = ctx
  const { api, url, userId } = parseCtx(ctx)
  chat = new ChatPrivate(api, url, userId, $config.event)

  const [res, err] = await api.private.getChats()
  if (err) {
    console.error(err.response.data)
  } else {
    const chats = ctx.$api.helpers.getList(res)
    const unreadCount = chats
      .reduce((sum: number, c: any) => sum + c.unreadCount, 0)
    store.commit("user/SET_UNREAD_MESSAGES_COUNT", unreadCount)
  }

  chat.on.newMessage = async ({ message }: { message: any }) => {
    const { route } = ctx
    if (route.path.includes("/user/chat") && route.query.id === message.chat) return

    store.commit("user/SET_UNREAD_MESSAGES_COUNT",
      store.state.user.unreadMessagesCount + 1)

    const ChatNotification =
      (await import("@/components/chat/ChatNotification.vue")).default
    const vnode = window.$nuxt.$createElement(ChatNotification, {
      props: {
        photo: message.author.photo?.url || "",
        name: ctx.$fmt.name(message.author),
        link: ctx.app.localePath(`/user/chat?id=${message.chat}`),
        linkText: ctx.app.i18n.t("button.read"),
        text: msgToStr(message),
      },
    })
    Notification({
      title: ctx.i18n.t("message.chat.newMessage") as string,
      message: vnode,
      customClass: "notification",
    })
  }

  chat.join()
}

export default (ctx: NuxtContext) => ({
  start: getStart(ctx), stop () { chat?.leave() },
})
