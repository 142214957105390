// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pOfpdv1U{align-items:center;display:flex;flex-wrap:nowrap}.jTWTMXa4{color:var(--color-bg-invert);display:flex;flex-shrink:0;padding:1rem}.rNLtBWqF{--size:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "pOfpdv1U",
	"link": "jTWTMXa4",
	"linkIcon": "rNLtBWqF"
};
module.exports = ___CSS_LOADER_EXPORT___;
