// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4x3rzjpL{background:var(--color-gray-1);padding:0 var(--spacing-md)}@media(max-width:667.98px){._4x3rzjpL{padding:0 var(--spacing-sm)}}.Jvc56TKF{align-items:center;display:flex;justify-content:space-between;margin:0 auto;max-width:var(--content-width)}@media(max-width:947.98px){.Jvc56TKF{flex-direction:column}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_4x3rzjpL",
	"content": "Jvc56TKF"
};
module.exports = ___CSS_LOADER_EXPORT___;
