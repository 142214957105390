// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShV2e0TP{display:flex;flex-grow:1}@media(max-width:1023.98px){.ShV2e0TP{align-items:center;flex-direction:column;margin:0 auto;padding:calc(var(--spacing-default)*8) 0 calc(var(--spacing-default)*12);width:-moz-max-content;width:max-content}}.Gf86HLk5{align-items:center;color:var(--color-bg-invert);display:flex;font-size:.88rem;font-weight:600;height:100%;letter-spacing:.75px;line-height:1.2;padding:0 .75rem;white-space:nowrap}@media(hover:hover){.Gf86HLk5:hover{color:var(--color-gray-3)}}@media(hover:none){.Gf86HLk5:active{color:var(--color-gray-3)}}@media(max-width:1023.98px){.Gf86HLk5{height:auto;justify-content:center;padding:1rem;width:100%}}.Gf86HLk5.nuxt-link-active{color:var(--color-gray-3);cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "ShV2e0TP",
	"link": "Gf86HLk5"
};
module.exports = ___CSS_LOADER_EXPORT___;
